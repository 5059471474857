<template>
    <div class="page">
        <el-backtop><i class="el-icon-caret-top"></i></el-backtop>
        <img src="../../assets/images/about_1.png" alt="" srcset="" class="imgs">
        <div class="con">
            <img src="../../assets/images/cs.png" alt="" srcset="" class="msg">
            <div class="map">
                <el-amap :dragEnable="true" :zoomEnable="true" class="amap-box" :zoom="25" :vid="'amap-vue'" :position="center" :center="center">
                    <!-- <a href="https://uri.amap.com/marker?position=113.4365&lat=23.16775&name=三川田"> -->
                        <el-amap-marker :position="center" :icon="icon"></el-amap-marker>
                    <!-- </a> -->
                </el-amap>
            </div>
        </div>
        <img src="../../assets/images/wenli-2.png" alt="" srcset="" class="wl2">
    </div>
</template>
<script>
import Vue from 'vue'
import VueAMap from 'vue-amap';
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  serviceHost: process.env.VUE_APP_SERVICE_HOST,
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
});
export default {
    data(){
        return {
            center: [113.4365, 23.16775],
            icon: require('../../assets/images/frame.png')
        }
    },
    created(){
    },
    methods: {
    }
    
}
</script>
<style lang="scss" scoped>
.page{
    // height: 100%;
    background: #fdfdfd;
    position: relative;
    // display: flex;
    // justify-content: center;
    .imgs{
        width: 100%;
        display: block;
        position: relative;
        z-index: 1;
    }
    .con{
        height: 654px;
        width: 100%;
        position: relative;
        display: flex;
        .msg{
            width: 437px;
            height: 272px;
            margin: 120px 197px 0 178px;
        }
        .map{
            width: 924px;
            height: 512px;
            background: #fdfdfd;
            margin: 71px 0;
        }
    }
    .wl2{
        width: 868.13px;
        height: 799.21px;
        position: absolute;
        bottom: 0;
        left: 16px;
    }
}
</style>